import { IWSysHub, IWSysHubEvent } from '@coimbra-its/websys-ux-core'
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

import type { SIWECreateMessageArgs, SIWESession, SIWEVerifyMessageArgs } from '@web3modal/siwe'
import { createSIWEConfig, formatMessage } from '@web3modal/siwe'
import { SiweMessage } from 'siwe'
import { TSdbn2Api, useSdbn2Api } from '../store/sdbn2.api'
import { useRef, useState } from 'react'

// 1. Get projectId
//export const projectId = '7a277e3a4080047662df69371aeaacca' // ???
export const projectId = '63242127221a9365f9f87e48d552536d' // Péter

// 2. Set chains
/* TEST:
const mainnet = {
	chainId: 0x61,
	name: 'Binance Smart Chain Testnet',
	currency: 'tBNB',
	explorerUrl: 'https://testnet.bscscan.com',
	rpcUrl: 'https://data-seed-prebsc-1-s3.binance.org:8545'
}*/

export const mainnet = {
	chainId: 0x38,
	name: 'Binance Smart Chain',
	currency: 'BNB',
	explorerUrl: 'https://bscscan.com/',
	rpcUrl: 'https://bsc-dataseed1.binance.org/',
}

// 3. Create a metadata object
export const metadata = {
	name: 'sunmoney-walletconect',
	description: 'Sunmoney Walletconnect',
	//url: 'https://walletconnect-demo.sunmoney.net', // origin must match your domain & subdomain
	//icons: ['https://walletconnect-demo.sunmoney.net/icons']
	url: 'https://token.sdbn2.com', // origin must match your domain & subdomain
	icons: ['https://token.sdbn2.com/icons']
}

// 4. Create Ethers config
export const ethersConfig = defaultConfig({
	/*Required*/
	metadata,
	//enableEmail: true,
	/*Optional*/
	enableEIP6963: true, // true by default
	enableInjected: true, // true by default
	enableCoinbase: true, // true by default
	rpcUrl: mainnet.rpcUrl, //??   '...', // used for the Coinbase SDK
	defaultChainId: mainnet.chainId,  //??  1, // used for the Coinbase SDK
})



async function getMessageParams() {
	return {
		domain: window.location.host,
		uri: window.location.origin,
		chains: [mainnet.chainId],
		statement: 'Please sign with your account'
	}
}

// ==========================================================================================================
// ---------------------------------------------- SIWE ------------------------------------------------------
// ==========================================================================================================

// (sign in with ethers) https://docs.walletconnect.com/web3modal/react/siwe

/*	az eláírás valamiért így megy:
	- getSession
	- getNonce
	- signOut (?????)
	- createMessage
	- verifyMessage()


*/


export function useCreateWeb3Modal(hub: IWSysHub) {

	const api = useSdbn2Api();
	const signInPost = api.signInPost.useAction();
	const signOutPost = api.signOutPost.useAction();
	const startupPost = api.startupPost.useAction();

	const [modalCreated, setModalCreated] = useState(false);
	const ignoreSignOut = useRef(false); // Valamiért meghívja token aláírás előtt feleslegesnek tűnő módon

	if (modalCreated)
		return;
	setModalCreated(true);

	// ----------------- from here run once ----------------

	const siweConfig = createSIWEConfig({
		// ----- ez a verziót ajánlja a dokumentáció:  https://docs.walletconnect.com/appkit/vue/core/siwe
		getMessageParams,
		createMessage: ({ address, ...args }: SIWECreateMessageArgs) => {
			console.log('%c****************************** createMessage ****************************', 'color:red;', address, args);
			ignoreSignOut.current = false;
			let msg = formatMessage(args, address);
			// TODO:  valmiért undefined-ként adta vissza az "Issued At"-et, pedig a példák szerint (is) kell
			msg = msg.replace('Issued At: undefined', `Issued At: ${new Date().toISOString()}`);
			console.log('-------  formatMessage ----------', msg);
			return msg;
		},
		getNonce: async (address?: string) => {
			console.log('%c****************************** getNonce ****************************', 'color:red;', address);
			if (!address)
				throw new Error('No address');

			const loadingEvent: IWSysHubEvent = { severity: 'loading', message: 'Loading...' };
			hub.addEvent(loadingEvent);
			try {
				const res = await startupPost({ walletAddress: address, nonce: 'minekide???' });
				console.log('ide eljutok??');
				if (!res.nonce)
					throw new Error('No nonce!');
				window.localStorage.setItem('sdbn1_wallet', address);
				window.localStorage.setItem('sdbn1_nonce', res.nonce);
				window.localStorage.setItem('sdbn1_nonce_is_signed', '0');
				console.log('---> visszatérek a nonce-szal: ', res.nonce);
				ignoreSignOut.current = true;
				return res.nonce;
			} catch (ex) {
				console.error(ex);
				throw ex;
			} finally {
				hub.removeEvent(loadingEvent);
			};

		},
		getSession: async () => {
			console.log('%c****************************** getSession ****************************', 'color:red;');
			//TODO: Itt kéne aszinkron ellenőrizni, hogy él-e session szerver oldalon?
			const sess_address = window.localStorage.getItem('sdbn1_wallet');
			const sess_sdbn1_nonce_is_signed = window.localStorage.getItem('sdbn1_nonce_is_signed') === '1';
			if (sess_address && sess_sdbn1_nonce_is_signed) {
				const sess: SIWESession = {
					address: sess_address,
					chainId: mainnet.chainId //TODO!!
				}
				return sess;
			} else {
				return null;
			}
		},
		verifyMessage: async (args: SIWEVerifyMessageArgs) => {
			console.log('%c****************************** verifyMessage ****************************', 'color:red;', args);

			const sess_address = window.localStorage.getItem('sdbn1_wallet');
			const sess_nonce = window.localStorage.getItem('sdbn1_nonce');
			console.log(sess_address, sess_nonce);
			const loadingEvent: IWSysHubEvent = { severity: 'loading', message: 'Loading...' };
			hub.addEvent(loadingEvent);
			try {
				const res = await signInPost({
					walletAddress: sess_address || '',
					nonce: sess_nonce || '',
					nonceSigned: args.signature,
					message: args.message,
				})
				console.log('---- verifyMessage vége....', res);
				window.localStorage.setItem('sdbn1_nonce_is_signed', '1');
				return true;

			} finally {
				hub.removeEvent(loadingEvent);
			}

		},
		signOut: async (...args) => {
			console.log('%c****************************** signOut ****************************', 'color:red;', ignoreSignOut, args);			
			if (ignoreSignOut.current)
				return false;
			const sess_address = window.localStorage.getItem('sdbn1_wallet');
			const sess_nonce = window.localStorage.getItem('sdbn1_nonce');
			const loadingEvent: IWSysHubEvent = { severity: 'loading', message: 'Loading...' };
			hub.addEvent(loadingEvent);
			try {
				await signOutPost({
					walletAddress: sess_address || '',
					nonce: sess_nonce || '',
				})
				window.localStorage.removeItem('sdbn1_wallet');
				window.localStorage.removeItem('sdbn1_nonce');
				window.localStorage.removeItem('sdbn1_nonce_is_signed');
				return true
			} finally {
				hub.removeEvent(loadingEvent);
			}
		},
	})

	// 5. Create a Web3Modal instance
	createWeb3Modal({
		ethersConfig,
		chains: [mainnet],
		projectId,
		enableAnalytics: false, // Optional - defaults to your Cloud configuration
		//siweConfig,
	})

}

/* SDBN1: 
export const usdt_address = "0x55d398326f99059fF775485246999027B3197955";  //ok
export const payout_address = "0xa737F3B9f5E73344770B76B0F39c63517968966C";
export const sale_address = "0x296682C515EAaB48F13F38C74797B0F40407B46e"; //... buy
export const token_address = "0xC605eac37a26927F38284387048AaF1395B1D9e3"; //ok
export const PAYOUT_IGNORE_ACCOUNTS = [sale_address];
*/

export const sale_address = "0x3eb4237E402d5954d6cE106C045c1aB473BCC39f";
export const usdt_address = "0x55d398326f99059fF775485246999027B3197955";
export const token_address = "0xEED7Cc0ea13987509d727577e634f6722333bbDF";
//export const payout_address_sdbn2 = "0xE9c12bbE1FB674FFBd1Ee1b24AfB44EB2554913b";
//export const payout_address_sdbn2 = "0xdDc15150771448e4ed79c6F0C70522b1E2757c8B";
export const payout_address_sdbn2 = "0x654443Ebb62dBab200aa90db749A0af74F3923D0";
export const payout_address_sdbn2plus = "0x62F915d3bCE0119862d3d68C1649341b930c1719";
export const PAYOUT_IGNORE_ACCOUNTS = [sale_address];

export const ADMINS : string[] = [
	'0xDE33d58193Cdec2a6383649353A2bFbFB037c4ed',	//kri
	'0x767771f79eb2D439ae7832a705485218E4b9cF95', //Teng
	'0x9eBcab26D0B13e68Db6aD1d594fD668cC9c1bE95', //Péter
	'0x383b730ca446b6606416546F943d8A5233D906B2', //Péter2
];


export const token_address_sdbn2plus = "0x94FB41C94ac603eDEcDd210cf77CF2FFC979EC91";